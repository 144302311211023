import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import { blogPosts } from '../data/blogs';

const BlogDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const blogPost = blogPosts.find((post) => post.id === parseInt(id || '', 10));

  if (!blogPost) {
    return (
      <Container maxWidth="md" style={{ paddingTop: '50px', paddingBottom: '50px' }}>
        <Typography 
          variant="h4" 
          align="center" 
          gutterBottom 
          style={{ fontWeight: 'bold', color: '#f44336' }}
        >
          Blog not found
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" style={{ paddingTop: '50px', paddingBottom: '50px' }}>
      <Paper elevation={3} style={{ padding: '20px', borderRadius: '8px', backgroundColor: '#fff' }}>
        <Typography 
          variant="h3" 
          gutterBottom 
          style={{ fontWeight: 'bold', color: '#333' }}
        >
          {blogPost.title}
        </Typography>
        <Typography 
          variant="body2" 
          color="textSecondary" 
          gutterBottom 
          style={{ marginBottom: '20px' }}
        >
          {blogPost.date}
        </Typography>
        <Box 
          mt={4}
          style={{ lineHeight: '1.6' }}
        >
          <Typography 
            variant="body1" 
            component="div" 
            dangerouslySetInnerHTML={{ __html: blogPost.content }} 
            style={{ whiteSpace: 'pre-line' }} // Ensures content formatting is preserved
          />
        </Box>
      </Paper>
    </Container>
  );
};

export default BlogDetail;