// Home.tsx
import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import TechnologyStack from '../components/technologystack';
import SectionDivider from '../components/SectionDivider'; // Assuming you have a SectionDivider component

const Home: React.FC = () => {
  const { t } = useTranslation();
  const [showGreeting, setShowGreeting] = useState(true);

  // Automatically hide the greeting after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowGreeting(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      sx={{
        background: 'linear-gradient(to right, #007bff, #67aefb)',
        color: '#fff',
        py: 10,
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* Particle Effect Background */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
          background: 'rgba(255, 255, 255, 0.1)',
          pointerEvents: 'none',
        }}
      />

      <Container maxWidth="md" sx={{ position: 'relative', zIndex: 1 }}>
        {/* Greeting Animation */}
        <AnimatePresence>
          {showGreeting && (
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.6 }}
              style={{
                position: 'absolute',
                top: '2%',
                left: '-25%',
                transform: 'translate(0%, 70%)',
                zIndex: 2,
                textAlign: 'center',
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 700,
                  fontSize: '4rem',
                  letterSpacing: '2px',
                  color: '#FFFF00', // Neon pink to make it pop against the blue background
                  textShadow: '0px 0px 10px rgba(255, 64, 129, 0.7), 0px 0px 20px rgba(255, 64, 129, 0.5)', // Glow effect
                }}
              >
                Hi there!
              </Typography>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Main Content Animation */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.5 }}
        >
          <Typography variant="h2" component="h1" gutterBottom>
            {t('welcome')}
          </Typography>
          <Typography variant="h5" paragraph>
            {t('page')}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            href="/contact"
            sx={{
              mt: 4,
              boxShadow: 5,
              textTransform: 'uppercase',
              transition: 'transform 0.3s',
              '&:hover': {
                transform: 'scale(1.1)',
              },
            }}
          >
            Get in Touch
          </Button>
          <SectionDivider />
        </motion.div>

        {/* Technology Stack */}
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
          <Box mt={8} mb={4}>
            <TechnologyStack />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Home;