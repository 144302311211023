import React, { useState } from 'react';
import { TextField, Container, List, ListItem, ListItemText, Typography } from '@mui/material';
import { BlogPost } from '../data/blogs';

const BlogSearch: React.FC<{ posts: BlogPost[] }> = ({ posts }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPosts, setFilteredPosts] = useState(posts);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    setFilteredPosts(
      posts.filter(post =>
        post.title.toLowerCase().includes(term) || post.content.toLowerCase().includes(term)
      )
    );
  };

  return (
    <Container maxWidth="md" style={{ paddingTop: '50px', paddingBottom: '50px' }}>
      <TextField
        label="Search Blogs"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearch}
        style={{ marginBottom: '20px' }}
      />
      <List>
        {filteredPosts.length ? (
          filteredPosts.map(post => (
            <ListItem key={post.id} component="a" href={`/blog/${post.id}`}>
               <ListItemText
                primary={
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    {post.title}
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="textSecondary">
                    {post.date}
                  </Typography>
                }
                primaryTypographyProps={{ style: { color: '#333' } }}
                secondaryTypographyProps={{ style: { color: '#777' } }}
              />
            </ListItem>
          ))
        ) : (
          <ListItem>
            <ListItemText primary="No posts found" />
          </ListItem>
        )}
      </List>
    </Container>
  );
};

export default BlogSearch;