import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const WhyItMattersSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container sx={{ py: 8 }}>
      <Typography variant="h4" gutterBottom>
        {t('why_it_matters_title')}
      </Typography>
      <Box mb={3}>
        <Typography variant="body1" paragraph>
          {t('why_it_matters_desc')}
        </Typography>
        <Typography variant="body1">
          {t('contact_invite')}
        </Typography>
      </Box>
    </Container>
  );
};

export default WhyItMattersSection;