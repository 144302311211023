import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

interface TechnologyCardProps {
  icon: React.ReactNode;
  title: string;
  descriptionKey: string;
}

const TechnologyCard: React.FC<TechnologyCardProps> = ({ icon, title, descriptionKey }) => {
  const { t } = useTranslation();

  return (
    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
      <Card
        sx={{
          minHeight: '200px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
          textAlign: 'center',
          backgroundColor: '#f5f5f5',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          borderRadius: '15px',
          transition: 'transform 0.3s ease-in-out',
        }}
      >
        <CardContent>
          <Box mb={2}>
            {icon}
          </Box>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {t(descriptionKey)}
          </Typography>
        </CardContent>
      </Card>
    </motion.div>
  );
};

export default TechnologyCard;