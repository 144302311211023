import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import TechnologyCard from './technologycard';
import { FaDatabase, FaDocker, FaJava, FaReact, FaPython, FaJs, FaHtml5, FaPhp, FaCode } from 'react-icons/fa';
import { SiMongodb, SiPostgresql, SiMysql, SiRedis, SiScala, SiDart, SiFlutter, SiBlockchaindotcom, SiOpenai, SiTypescript, SiSpring, SiCsharp } from 'react-icons/si';

const technologies = [
    { icon: <FaDatabase size={40} color="#0078d7" />, title: 'MSSQL', descriptionKey: 'mssql_desc' },
    { icon: <SiMysql size={40} color="#4479A1" />, title: 'MySQL', descriptionKey: 'mysql_desc' },
    { icon: <SiPostgresql size={40} color="#336791" />, title: 'PostgreSQL', descriptionKey: 'postgresql_desc' },
    { icon: <FaDocker size={40} color="#2496ED" />, title: 'Docker', descriptionKey: 'docker_desc' },
    { icon: <SiMongodb size={40} color="#47A248" />, title: 'MongoDB', descriptionKey: 'mongodb_desc' },
    { icon: <SiRedis size={40} color="#DC382D" />, title: 'Redis', descriptionKey: 'redis_desc' },
    { icon: <SiBlockchaindotcom size={40} color="#2F3134" />, title: 'Hyperledger Fabric', descriptionKey: 'hyperledger_fabric_desc' },
    { icon: <SiCsharp size={40} color="#DEA584" />, title: 'C#', descriptionKey: 'csharp_desc' },
    { icon: <FaJava size={40} color="#007396" />, title: 'Java', descriptionKey: 'java_desc' },
    { icon: <SiScala size={40} color="#DC322F" />, title: 'Scala', descriptionKey: 'scala_desc' },
    { icon: <FaPython size={40} color="#3776AB" />, title: 'Python', descriptionKey: 'python_desc' },
    { icon: <FaReact size={40} color="#61DAFB" />, title: 'React', descriptionKey: 'react_desc_t' },
    { icon: <SiDart size={40} color="#0175C2" />, title: 'Dart', descriptionKey: 'dart_desc' },
    { icon: <SiFlutter size={40} color="#02569B" />, title: 'Flutter', descriptionKey: 'flutter_desc' },
    { icon: <SiOpenai size={40} color="#02569B" />, title: 'Ollama', descriptionKey: 'ollama_desc' },
    { icon: <SiTypescript size={40} color="#3178C6" />, title: 'TypeScript', descriptionKey: 'typescript_desc' },
    { icon: <FaJs size={40} color="#F7DF1E" />, title: 'JavaScript', descriptionKey: 'javascript_desc' },
    { icon: <FaHtml5 size={40} color="#E34F26" />, title: 'HTML', descriptionKey: 'html_desc' },
    { icon: <FaPhp size={40} color="#777BB4" />, title: 'PHP', descriptionKey: 'php_desc' },
    { icon: <FaCode size={40} color="#00ADD8" />, title: 'Go', descriptionKey: 'go_desc' },
    { icon: <FaDatabase size={40} color="#006400" />, title: 'Entity Framework', descriptionKey: 'entity_framework_desc' },
    { icon: <SiSpring size={40} color="#6DB33F" />, title: 'Spring Boot', descriptionKey: 'spring_boot_desc' },
  ];

const TechnologyStack: React.FC = () => {
    return (
      <Box sx={{ flexGrow: 1, padding: '20px 0' }}>
        <Typography variant="h4" align="center" gutterBottom>
          Technology Stack
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {technologies.map((tech, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <TechnologyCard
                icon={tech.icon}
                title={tech.title}
                descriptionKey={tech.descriptionKey}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };
  
  export default TechnologyStack;