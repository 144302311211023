import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react'
import { createRoot } from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';


const instance = createInstance({
  urlBase: 'https://analytics.rutgervanhaaren.nl',
  siteId: 12,
  userId: 'undefined', // optional, default value: `undefined`.
  srcUrl: 'https://analytics.rutgervanhaaren.nl/js/container_5XWacASK.js', // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: false,
    setRequestMethod: 'POST'
  }
})

const container = document.getElementById('root');
const root = createRoot(container!);

root.render( 
<React.StrictMode>
<HelmetProvider>
<Helmet>
        <title>GGWP's website</title>
        <link rel="canonical" href="https://www.ggwp.eu/" />
        <meta property="og:title" content="GGWP's website"/>
        <meta property="og:description" content="GGWP's website where you can find out about his portfolio"/>
        <meta name="description" content="GGWP's website where you can find out about his portfolio"></meta>
      </Helmet>
  <I18nextProvider i18n={i18n}>
  <MatomoProvider value={instance}/>
    <App />
  </I18nextProvider>
  </HelmetProvider>
</React.StrictMode>
);

reportWebVitals();