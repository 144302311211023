import React from 'react';
import { Container, Typography, Grid, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';

const deploymentTools = [
  { name: 'GitHub Actions', descKey: 'github_actions_desc' },
  { name: 'PM2', descKey: 'pm2_desc' },
];

const DeploymentSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container sx={{ py: 8 }}>
      <Typography variant="h4" gutterBottom>
        {t('deployment_title')}
      </Typography>
      <Grid container spacing={4}>
        {deploymentTools.map((tool, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>
                {tool.name}
              </Typography>
              <Typography variant="body1">{t(tool.descKey)}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default DeploymentSection;