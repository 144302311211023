// SectionDivider.tsx
import React from 'react';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';

const SectionDivider: React.FC = () => {
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: '100%' }}
      transition={{ duration: 0.8 }}
    >
      <Box
        sx={{
          height: '5px',
          backgroundColor: 'primary.main',
          borderRadius: '5px',
          my: 4,
        }}
      />
    </motion.div>
  );
};

export default SectionDivider;