import React from 'react';
import { Container, Typography, Box, Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';

const About: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: 'primary.main',
        color: '#fff',
        py: 8,
        textAlign: 'center',
      }}
    >
      <Container maxWidth="md">
        <Avatar
          alt="GGWP"
          src="/path-to-your-profile-image.jpg"
          sx={{ width: 120, height: 120, mb: 4, mx: 'auto' }}
        />
        <Typography variant="h3" component="h1" gutterBottom>
          GGWP
        </Typography>
        <Typography variant="h5" paragraph>
          Software Developer | IT Enthusiast | Lifelong Learner
        </Typography>
        <Typography variant="body1" paragraph>
          {t('about_intro')}
        </Typography>
      </Container>
    </Box>
  );
};

export default About;