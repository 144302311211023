import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineMessage, AiOutlineClose } from 'react-icons/ai';
import { Box, Typography, Button, TextField, CircularProgress, Paper, Divider, IconButton } from '@mui/material';
import { motion } from 'framer-motion';
import { Rnd } from 'react-rnd';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface Message {
  type: string;
  prompt: string;
  timestamp: number;
}

const ChatBot: React.FC = () => {
  const [history, setHistory] = useState<Message[]>([]);
  const [context, setContext] = useState<any[]>([]);
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const chatBodyRef = useRef<HTMLDivElement>(null);

  const toggleChatBot = () => {
    setIsExpanded(!isExpanded);
  };

  const closeChatBot = () => {
    setIsExpanded(false);
  };

  const sendPrompt = async (prompt: string, currentContext: Message[]) => {
    setLoading(true);

    const userMessage: Message = {
      type: 'user',
      prompt: prompt,
      timestamp: Date.now(),
    };

    setHistory(prevHistory => [...prevHistory, userMessage]);

    const placeholderMessage: Message = {
      type: 'server',
      prompt: "",
      timestamp: Date.now(),
    };

    let tempIndex = history.length;
    let serverResponse = '';

    setHistory(prevHistory => {
      const updatedHistory = [...prevHistory, placeholderMessage];
      tempIndex = updatedHistory.length - 1;
      return updatedHistory;
    });

    const processChunk = (chunk: string) => {
      const jsonParts = chunk.split('\n').filter(Boolean);

      jsonParts.forEach((part) => {
        if (part.startsWith('data:')) {
          const data = part.replace('data: ', '').trim();

          if (data === '[DONE]') {
            setLoading(false);
            return; // End processing
          }

          try {
            const parsedPart = JSON.parse(data);
            serverResponse += parsedPart.response || '';

            setHistory(prevHistory => {
              const newHistory = [...prevHistory];
              newHistory[tempIndex].prompt = serverResponse;
              return newHistory;
            });

            if (parsedPart.context) {
              setContext(parsedPart.context);
            }
          } catch (e) {
            console.error('Error parsing response:', e);
          }
        }
      });
    };

    const apiUrl = process.env.REACT_APP_MODE === "PROD" ? 'https://ggwp.eu:8880/api/chat' : 'https://localhost:8880/api/chat';

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message: prompt, context: [...context] }),
      });

      if (!response.body) throw new Error('ReadableStream not supported by the response.');

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let done = false;

      while (!done) {
        const { value, done: readerDone } = await reader.read();
        const chunk = decoder.decode(value, { stream: true });
        processChunk(chunk);
        done = readerDone;
      }

    } catch (error) {
      console.error('Error sending prompt to the backend:', error);
      setLoading(false);
    }

    setPrompt('');
  };

  useEffect(() => {
    if (isExpanded && chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [history, isExpanded]);

  return (
    <>
      {/* Chatbot Icon */}
      <motion.div
        className="chatbot-icon"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
        onClick={toggleChatBot}
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          backgroundColor: '#007bff',
          color: '#fff',
          borderRadius: '50%',
          width: '60px',
          height: '60px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.2)',
          zIndex: 1000,
        }}
      >
        <AiOutlineMessage size={30} />
      </motion.div>

      {/* Chatbot Container */}
      {isExpanded && (
        <Rnd
          default={{
            x: window.innerWidth - 520,
            y: window.innerHeight - 400,
            width: 400,
            height: 500,
          }}
          minWidth={300}
          minHeight={400}
          bounds="window"
          style={{
            zIndex: 1000,
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.2)',
            bottom: '30%',
            top: '-20%'
          }}
        >
          <Paper elevation={3} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box
              className="chatbot-header"
              style={{
                backgroundColor: '#007bff',
                color: '#fff',
                padding: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
              }}
            >
              <Typography variant="h6">Chat with AI!</Typography>
              <IconButton size="small" onClick={closeChatBot} style={{ color: '#fff' }}>
                <AiOutlineClose />
              </IconButton>
            </Box>
            <Box className="chatbot-body" p={2} flex={1} overflow="auto" ref={chatBodyRef} style={{ backgroundColor: '#f7f8fa' }}>
              {history.map((item, index) => (
                <Box
                  key={index}
                  className={`message ${item.type}`}
                  mb={2}
                  style={{
                    textAlign: item.type === 'user' ? 'right' : 'left',
                  }}
                >
                  <Typography
                    variant="body2"
                    color={item.type === 'user' ? 'primary' : 'secondary'}
                    style={{
                      backgroundColor: item.type === 'user' ? '#cce5ff' : '#e2e3e5',
                      padding: '10px',
                      borderRadius: '10px',
                      display: 'inline-block',
                      maxWidth: '80%',
                      wordWrap: 'break-word',
                    }}
                  >
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {item.prompt}
                    </ReactMarkdown>
                  </Typography>
                </Box>
              ))}
            </Box>
            <Divider />
            <Box className="input-area" display="flex" alignItems="center" p={2}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Enter your prompt"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !loading && prompt.trim()) {
                    sendPrompt(prompt, context);
                    e.preventDefault(); // Prevent newline in TextField
                  }
                }}
                disabled={loading}
                autoFocus // Autofocus when expanded
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => sendPrompt(prompt, context)}
                disabled={loading || !prompt.trim()}
                style={{ marginLeft: '8px' }}
              >
                {loading ? <CircularProgress size={24} /> : 'Send'}
              </Button>
            </Box>
          </Paper>
        </Rnd>
      )}
    </>
  );
};

export default ChatBot;