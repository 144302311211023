import React from 'react';
import { Container, Typography, Grid, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';

const technologies = [
  { name: 'React', descKey: 'react_desc' },
  { name: 'TypeScript', descKey: 'typescript_desc' },
  { name: 'React Router', descKey: 'router_desc' },
  { name: 'Material-UI', descKey: 'material_ui_desc' },
  { name: 'i18next', descKey: 'i18n_desc' },
  { name: 'Framer Motion', descKey: 'framer_motion_desc' },
  { name: 'Ollama', descKey: 'ollama' },
];

const TechToolsSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container sx={{ py: 8 }}>
      <Typography variant="h4" gutterBottom>
        {t('tech_tools_title')}
      </Typography>
      <Grid container spacing={4}>
        {technologies.map((tech, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>
                {tech.name}
              </Typography>
              <Typography variant="body1">{t(tech.descKey)}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default TechToolsSection;