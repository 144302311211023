import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFound: React.FC = () => {
    const { t } = useTranslation();

  return (
    <Container
      maxWidth="sm"
      style={{ textAlign: 'center', paddingTop: '50px', paddingBottom: '50px' }}
    >
      <Typography variant="h1" component="h1" color="primary" gutterBottom>
        404
      </Typography>
      <Typography variant="h5" color="textSecondary" paragraph>
       { t("notfound") }
      </Typography>
      <Box mt={4}>
        <Button variant="contained" color="primary" component={RouterLink} to="/">
          Go Back Home
        </Button>
      </Box>
    </Container>
  );
};

export default NotFound;