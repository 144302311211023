import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MenuBar from './components/menubar';
import Home from './pages/home';
import Contact from './pages/contact';
import Servers from './pages/servers';
import Projects from './pages/projects'; 
import ChatBot from './components/chatbot';
import NotFound from './pages/notfound';
import BlogList from './pages/BlogList';
import BlogDetail from './pages/BlogDetail';
import './App.css';
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import AboutPage from './pages/AboutPage';

const App: React.FC = () => {
  const { trackPageView } = useMatomo();

  // Track page view
  React.useEffect(() => {
    trackPageView({
      documentTitle: '', // optional
      href: '', // optional
      customDimensions: [
        {
          id: 1,
          value: 'loggedIn',
        },
      ], // optional
    })
  }, [trackPageView])


  return (
    <Router>
      <MenuBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/servers" element={<Servers />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/blogs" element={<BlogList />} />
        <Route path="/blog/:id" element={<BlogDetail />} />
        <Route path="/about" element={<AboutPage />} />  
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ChatBot />
    </Router>
  );
};

export default App;
