import React from 'react';
import { IconButton, Container, Typography, Box } from '@mui/material';
import { FaDiscord } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const Contact: React.FC = () => {
  const { t } = useTranslation();


  function openDiscordApp() {
    var userId = "126772364611420160"; // Your Discord User ID Here
  
    if (window.navigator && window.navigator.userAgent.match(/(android|iphone|ipad)/i)) {
      // for andorid and ios devices
      window.location.href = "discord://user?id=" + userId;
    } else {
      // for other devices or webbrowsers
      window.open("https://discord.com/users/" + userId);
    }
  }

  
  return (
    
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        textAlign="center"
      >
        <IconButton
          edge="start"
          color="primary"
          aria-label="discord"
          href="_"
          target="_blank"
          style={{ fontSize: 50 }}
          onClick={() => openDiscordApp()}
        >
          <FaDiscord />
        </IconButton>
        <Typography variant="h4" gutterBottom>
          Contact Me
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {t('contact')}
        </Typography>
        <Typography variant="h6" style={{ marginTop: '20px' }}>
          Email: <a href="mailto:contact@ggwp.eu">contact@ggwp.eu</a>
        </Typography>
      </Box>
    </Container>
    
  );
};



export default Contact;