import React, { useEffect, useState } from 'react';
import { Container, Typography, Card, CardContent, Grid, CircularProgress, Button, Box } from '@mui/material';

interface GitHubRepo {
  id: number;
  name: string;
  description: string;
  html_url: string;
}

const Projects: React.FC = () => {
  const [repos, setRepos] = useState<GitHubRepo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchRepos = async () => {
      try {
        const response = await fetch('https://api.github.com/users/GGWPs/repos');
        const data = await response.json();
        setRepos(data);
      } catch (error) {
        console.error('Error fetching GitHub repos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRepos();
  }, []);

  return (
    <Box
    sx={{
      background: 'linear-gradient(to right, #007bff, #67aefb)',
      color: '#fff',
      py: 10,
      textAlign: 'center',
    }}
  >
    <Container >
      <Typography variant="h3" gutterBottom>
        My Projects
      </Typography>
      
      {/* Games Section */}
      <Typography variant="h4" gutterBottom>
        Games
      </Typography>
      <Grid container spacing={4} style={{ marginBottom: '40px' }}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                Sander's Journey
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                This is a game that I developed. Click the link below to download and play it!
              </Typography>
              <Button 
                variant="contained" 
                color="primary" 
                href="https://ggwp.stackstorage.com/s/2XLeaEypz6EYhlbN" 
                target="_blank"
              >
                Download Game
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                Bizarre Puzzler
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                This is a game that I developed in 2014 and was my first introduction to programming. I do not recommend playing it as it has very hard clear conditions.
              </Typography>
              <Button 
                variant="contained" 
                color="primary" 
                href="https://ggwp.stackstorage.com/s/WYj2sowQPSTsBMvj" 
                target="_blank"
              >
                Download Game
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* GitHub Projects Section */}
      <Typography variant="h4" gutterBottom>
        GitHub Projects
      </Typography>
      <Typography variant="body2" gutterBottom>
        Note: Most of my work is in private repositories which are not allowed to see the light. The following code is not a representative of my current skills with the framework/language.
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={4}>
          {repos.map(repo => (
            <Grid item xs={12} sm={6} md={4} key={repo.id}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {repo.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {repo.description}
                  </Typography>
                  <Typography variant="body2" color="primary">
                    <a href={repo.html_url} target="_blank" rel="noopener noreferrer">
                      View on GitHub
                    </a>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
    </Box>
  );
};

export default Projects;