import React from 'react';
import About from '../components/about/About';
import DeploymentSection from '../components/about/DeploymentSection';
import TechToolsSection from '../components/about/TechToolsSection';
import WhyItMattersSection from '../components/about/WhyItMattersSection';

const AboutPage: React.FC = () => {
  return (
    <>
      <About />
      <TechToolsSection />
      <DeploymentSection />
      <WhyItMattersSection />
    </>
  );
};

export default AboutPage;