import React from 'react';

const Servers = () => {
  return (
    <div>
      <h2>Servers</h2>
      <p>WIP</p>
    </div>
  );
};

export default Servers;