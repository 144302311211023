import React from 'react';
import { Container, Typography} from '@mui/material';
import { blogPosts } from '../data/blogs';
import BlogSearch from '../components/BlogSearch';

const BlogList: React.FC = () => {
  
  return (
    <Container maxWidth="md" style={{ paddingTop: '50px', paddingBottom: '50px' }}>
      <Typography 
        variant="h3" 
        align="center" 
        gutterBottom 
        style={{ marginBottom: '30px', fontWeight: 'bold', color: '#333' }}
      >
        Blogs
      </Typography>
      <BlogSearch posts={blogPosts}></BlogSearch>
    </Container>
  );
};

export default BlogList;